import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js'
import { getStripe } from '@/services/StripeService/StripePaymentService'
import { useLocale } from '@/utils/LocaleUtil'
import { EmbeddedStripeCheckoutSessionSkeleton } from './EmbeddedStripeCheckoutSessionSkeleton'

interface EmbeddedStripeCheckoutSessionProps {
  clientSecret?: string | null
  loading?: boolean
  onComplete?: () => void
}

export const EmbeddedStripeCheckoutSession = ({
  clientSecret,
  loading,
  onComplete,
}: EmbeddedStripeCheckoutSessionProps) => {
  const { locale } = useLocale()
  if (!loading && clientSecret) {
    return (
      <EmbeddedCheckoutProvider
        key={clientSecret}
        stripe={getStripe(locale)}
        options={{
          clientSecret,
          onComplete,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    )
  }

  return <EmbeddedStripeCheckoutSessionSkeleton />
}
