import { useMutation } from '@apollo/client'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { StripePaymentIntentMutation, StripePaymentIntentMutationVariables } from '@/types/codegen-federation'
import { Locale } from '@/utils/LocaleUtil'
import { logger } from '@/utils/logging'
import { STRIPE_PAYMENT_INTENT } from './queries'

let stripePromise: Promise<Stripe | null>

// https://stripe.com/docs/js/appendix/supported_locales
const stripeLocales = {
  'ach-UG': 'en',
  da: 'da',
  'de-DE': 'de',
  en: 'en',
  es: 'es-419',
  'es-ES': 'es',
  fi: 'fi',
  fr: 'fr',
  el: 'el',
  hr: 'hr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ko: 'ko',
  nl: 'nl',
  no: 'nb',
  pl: 'pl',
  pt: 'pt',
  'pt-BR': 'pt-BR',
  sv: 'sv',
  'zh-Hant': 'zh-TW',
  'zh-CN': 'zh',
} as const

export async function getStripe(locale: Locale): Promise<Stripe | null> {
  if (!stripePromise) {
    const key = process.env.NEXT_PUBLIC_STRIPE_KEY
    if (!key) {
      logger().error('Stripe key is not set')
      return null
    }

    stripePromise = loadStripe(key, {
      locale: stripeLocales[locale] || 'en',
    })
      .then((stripeInstance) => {
        if (!stripeInstance) {
          logger().error('Failed to initialize Stripe', { key })
          return null
        }
        return stripeInstance
      })
      .catch((error) => {
        logger().error('Error initializing Stripe', { error })
        return null
      })
  }
  return stripePromise
}

export const useGenerateStripePaymentIntent = () => {
  return useMutation<StripePaymentIntentMutation, StripePaymentIntentMutationVariables>(STRIPE_PAYMENT_INTENT, {
    errorPolicy: 'all',
  })
}
