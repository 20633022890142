import { gql } from '@apollo/client'

export const STRIPE_PAYMENT_INTENT = gql`
  mutation stripePaymentIntent($input: StripePaymentIntentInput!) {
    stripePaymentIntentMutation(input: $input) {
      clientSecret
    }
  }
`

export const GUEST_STRIPE_PAYMENT_INTENT = gql`
  mutation guestStripePaymentIntent($input: GuestStripePaymentIntentInput!) {
    guestStripePaymentIntentMutation(input: $input) {
      clientSecret
    }
  }
`
